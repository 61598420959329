<template>
  <div class="page-content" v-if="dailyMission">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Daily Mission"
          class="mb-3"
          :items="breadcrumbItems"
        />
      </b-col>
    </b-row>
    <section class="my-3">
      <h2 class="h2">Daily Mission</h2>
      <b-card class="mt-1">
        <validation-observer
          ref="updateForm"
          v-slot="{ handleSubmit }"
          class="mr-4 ml-4"
        >
          <b-form @submit.stop.prevent="handleSubmit()">
            <b-form-group
              label="Mission Name"
              label-for="h-mission-name"
              label-cols-md="3"
            >
              <ui-component
                :component-data="dailyMission.name"
                class="border"
              />
            </b-form-group>
            <b-form-group
              label="Mission Description"
              label-for="h-mission-description"
              label-cols-md="3"
            >
              <ui-component
                :component-data="dailyMission.description"
                class="border"
              />
            </b-form-group>
            <!-- <b-form-group
              label="Category"
              label-for="h-category"
              label-cols-md="3"
            >
              <b-form-select v-model="dailyMission.category" :options="categories" />
            </b-form-group> -->
            <validation-provider
              name="Mission key"
              v-slot="validationContext"
              :rules="{ required: true }"
            >
              <b-form-group
                label="Mission key"
                label-for="h-reward"
                label-cols-md="3"
              >
                <b-form-input
                  v-model="dailyMission.mission_key"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback id="input-category-name-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div v-for="(item, index) in dailyMission.rewards" :key="index">
              <validation-provider
                name="Reward Type"
                v-slot="validationContext"
                :rules="{ required: true }"
              >
                <b-form-group
                  label="Reward Type"
                  label-for="h-reward"
                  label-cols-md="3"
                >
                  <b-form-select
                    v-model="item.type"
                    :options="missionRewards"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback id="input-category-name-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="Amount"
                v-slot="validationContext"
                :rules="{ required: true }"
              >
                <b-form-group
                  label="Amount"
                  label-for="h-amount"
                  label-cols-md="3"
                >
                  <b-form-input
                    v-model="item.amount"
                    :state="getValidationState(validationContext)"
                    placeholder="Amount"
                  />
                  <b-form-invalid-feedback id="input-category-name-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </section>

    <action-buttons
      update-button
      back-button
      @update="update"
      :is-updating="isUpdating"
      :is-deleting="isDeleting"
    />
  </div>
</template>

<script>
import service from "../service";
import missionRewardService from "./../../mission_reward/service";
import pronunLessonService from "../../../pronun/service";
import { categories, selectRewards } from "./../constants";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      isUpdating: false,
      isDeleting: false,
      dailyMission: null,
      lessons: [],
      missionRewards: [],
      categories,
    };
  },
  computed: {
    dailyMissionId() {
      return this.$route.params.daily_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Daily Mission List",
          to: { name: "mission-daily-list" },
        },
      ];
      items.push({ text: "Daily Mission Detail", active: true });
      return items;
    },
  },
  watch: {
    "daily.category": function (newValue) {
      if (newValue) {
        this.getLessons();
      }
    },
  },
  async created() {
    this.getDailyMission();
    this.getMissionRewards();
  },
  methods: {
    async getMissionRewards() {
      const { data } = await missionRewardService.getAll();
      if (data && data.data.list && data.data.list.length) {
        this.missionRewards = data.data.list.map((reward) => {
          return {
            text: reward.type,
            value: reward.type,
          };
        });
        return;
      }

      this.missionRewards = selectRewards;
    },

    async getLessons() {
      let response = await pronunLessonService.getAll();
      if (response) {
        this.lessons = response.list.map((item) => {
          return {
            value: item._id,
            text: item.title,
          };
        });
      }
    },

    async getDailyMission() {
      this.dailyMission = await service.get({ id: this.dailyMissionId });
    },
    async update() {
      let validation = await this.$refs.updateForm.validate();
      if (validation) {
        this.isUpdating = true;
        await service.update({
          data: JSON.stringify(this.dailyMission),
        });
        this.isUpdating = false;
      }
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
